import {toast} from "react-toastify";
import {CheckCircle, Error, HourglassEmpty} from "@material-ui/icons";
import {useUserStore} from "@/stores/user.store";

const IconSuccess = CheckCircle;
const IconError = Error;
const IconPending = HourglassEmpty;

export interface ToastPromiseMessages {
    pending?: string,
    success?: string,
    error?: string
}

function messagePasses(message: string): boolean {
    return (! message.match(/([a-zA-Z0-9]\.[a-zA-Z0-9])/gm)) // Fail if string is an untranslated message
        && (message !== ''); // Fail if message is empty
}

function flattenPromise<T>(p: Promise<T> | (() => Promise<T>)): Promise<T> {
    if (p instanceof Promise){
        return p;
    } else {
        return p();
    }
}

function isUnauthorizedMessage(e: any){
    return e.toString().replaceAll('Error: ', '') === 'Unauthorized';
}

function hasSessionExpired(){
    return useUserStore.getState().token === null;
}

export function toastPromise<T>(p: Promise<T> | (() => Promise<T>), messages: ToastPromiseMessages, bypassSessionCheck: boolean = false): Promise<T> {
    if (!bypassSessionCheck && hasSessionExpired()){
        return flattenPromise(p);
    }

    Object.keys(messages).forEach(key => {
        if (! messagePasses(messages[key as keyof ToastPromiseMessages] ?? '')) {
            messages[key as keyof ToastPromiseMessages] = '';
        }
    });

    return toast.promise(p, {
        pending: {
            render() {
                return messages.pending || '';
            },
            icon: IconPending
        },
        success: {
            render() {
                return messages.success || '';
            },
            icon: IconSuccess
        },
        error: {
            render() {
                return messages.error || '';
            },
            icon: IconError
        }
    });
}

export function toastSuccess(message: string, bypassSessionCheck: boolean = false) {
    if (!bypassSessionCheck && hasSessionExpired()){
        return;
    }

    if (! messagePasses(message)){
        return;
    }

    toast.success(message, {
        icon: IconSuccess
    });
}

export function toastError(message: string, bypassSessionCheck: boolean = false) {
    if (!bypassSessionCheck && hasSessionExpired()){
        return
    }

    if (! messagePasses(message)){
        return;
    }

    toast.error(message, {
        icon: IconError,
    });
}

function isErrorUnauthorized(e: any){
    return e.toString().replaceAll('Error: ', '') === 'Unauthorized';
}

export async function toastIfFailed<T>(p: Promise<T> | (() => Promise<T>), errorMessage: string = '', bypassSessionCheck: boolean = false): Promise<T> {
    if (!bypassSessionCheck && hasSessionExpired()) {
        return flattenPromise(p);
    }

    if (!messagePasses(errorMessage)) {
        return flattenPromise(p);
    }

    try {
        return await flattenPromise(p);
    } catch (e) {
        if (!isErrorUnauthorized(e)) {
            toastError(errorMessage, true);
        }

        throw e;
    }
}
