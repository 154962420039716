'use client';

import {useRouter} from "next/navigation";
import {useUserStore} from "@/stores/user.store";
import {useEffect} from "react";
import {useMainStore} from "@/stores/main.store";

export default function Home() {
    const router = useRouter();
    const {token, getUser, isAdmin} = useUserStore();
    const {adminModeOn} = useMainStore();

    useEffect(() => {
        if (!token) {
            router.push('/login');
            return;
        }

        getUser()
            .then(() => {
                if (isAdmin() && adminModeOn) {
                    router.push('/admin');
                } else {
                    router.push('/dashboard');
                }
            })
            .catch(() => {
                router.push('/login');
            });
    }, [token]);

    return (
        <></>
    );
}
