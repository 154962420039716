import {useTranslations} from "next-intl";
import {create} from "zustand";

interface TranslationsState {
    t?: ReturnType<typeof useTranslations<string>>
    setT: (t: ReturnType<typeof useTranslations<string>>) => void;
}

export const useTranslationsStore = create<TranslationsState>((set) => ({
    setT: (t) => set({t}),
}));
