'use client';

import {useUserStore} from "@/stores/user.store";
import {RefreshPayload} from "@/types/refresh-payload";
import {Token} from "@/types/token";
import {doPost} from "@/services/api.service";
import {toastError, toastIfFailed} from "@/services/toast.service";
import {useTranslationsStore} from "@/stores/translations.store";
import {jsonHeaders} from "@/constants/headers";

function injectAuthToken(baseHeaders: HeadersInit, token: Token | null): HeadersInit {
    if (!token) {
        return baseHeaders;
    }

    return {
        ...baseHeaders,
        'Authorization': `${token.token_type} ${token.access_token}`,
    };
}

const refreshRequest = doPost<RefreshPayload, Token>('api/auth/refresh');

export async function fetchWithAuth(url: string | URL, options: RequestInit): Promise<Response> {
    const t = useTranslationsStore.getState().t;

    return fetchWithAuthWithoutToast(url, options);
    // return toastIfFailed(() => {
    //     return fetchWithAuthWithoutToast(url, options);
    // }, t ? t('errors.sessionExpired') : '');
}

async function fetchWithAuthWithoutToast(url: string | URL, options: RequestInit): Promise<Response> {
    const t = useTranslationsStore.getState().t;

    const token = useUserStore.getState().token;
    const setToken = (token: Token) => {
        useUserStore.setState({token})
    }

    const headers = injectAuthToken(options.headers || {}, token);
    const res = await fetch(url, {...options, headers});
    if (res.status === 401) {
        if (token) {
            try {
                const refreshedToken = await refreshRequest({
                    refresh_token: token.refresh_token,
                }, jsonHeaders, {
                    auth: false,
                });
                setToken(refreshedToken);
                const newHeaders = injectAuthToken(options.headers || {}, refreshedToken);
                const resRetried = await fetch(url, {...options, headers: newHeaders});

                return resRetried;
            } catch (e) {
                toastError(t ? t('errors.sessionExpired') : '');
                useUserStore.setState({token: null, user: null});
                throw new Error('Unauthorized');
            }
        }

        toastError(t ? t('errors.sessionExpired') : '');
        throw new Error('Unauthorized');
    }

    return res;
}
