import {create} from "zustand";
import {persist} from "zustand/middleware";
import {User} from "@/types/user";
import {Building} from "@/types/building";
import {Account} from "@/types/account";
import {Group} from "@/types/group";


export interface MainState {
    user: User | null,
    adminUserSearchTerm: '',
    adminBuildingSearchTerm: '',
    adminGatewaySearchTerm: '',
    adminDeviceSearchTerm: '',
    accounts: Account[],
    translators: [],
    users: [],
    unfilteredUsers: [],
    buildings: Building[],
    accountBuildings: [],
    unfilteredBuildings: [],
    gateways: [],
    unfilteredGateways: [],
    devices: [],
    buildingDevices: [],
    unfilteredDevices: [],
    groupsOfDevices: [],
    buildingGroupsOfDevices: [],
    selectedBuildingDetails: Building | null,
    selectedAccount: Account | null,
    selectedItem: null,
    selectedUser: null,
    selectedBuilding: Building | null,
    selectedGateway: null,
    selectedDevice: null,
    selectedGroupOfDevices: Group | null,
    selectedPage: null,
    item: Building | null,
    loading: boolean,
    reload: string,
    adminModeOn: boolean,
    isUpdateAccountDialogOpen: false,
    isUpdateUserDialogOpen: false,
    isUpdateBuildingDialogOpen: false,
    isUpdateGatewayDialogOpen: false,
    isUpdateDeviceDialogOpen: false,
    isUpdateGroupOfDevicesDialogOpen: false,
    isAddAccountDialogOpen: false,
    isAddAlertDialogOpen: false,
    alertDialogOpen: false,
    currentUserBuildings: false,
    isCreateUserDialogOpen: false,
    isCreateBuildingDialogOpen: false,
    isCreateGatewayDialogOpen: false,
    isCreateDeviceDialogOpen: false,
    isAddToDeviceGroupDialogOpen: false,
    updatedUserSuccess: false,
    addGroupDevice: null,
    isMilink: false,
    consultantMode: false,
    setMain: (update: Partial<MainState>) => void,
    reset: () => void
}

const initialState: Omit<MainState, 'setMain' | 'reset'> = {
    user: null,
    adminUserSearchTerm: '',
    adminBuildingSearchTerm: '',
    adminGatewaySearchTerm: '',
    adminDeviceSearchTerm: '',
    accounts: [],
    translators: [],
    users: [],
    unfilteredUsers: [],
    buildings: [],
    accountBuildings: [],
    unfilteredBuildings: [],
    gateways: [],
    unfilteredGateways: [],
    devices: [],
    buildingDevices: [],
    unfilteredDevices: [],
    groupsOfDevices: [],
    buildingGroupsOfDevices: [],
    selectedBuildingDetails: null,
    selectedItem: null,
    selectedUser: null,
    selectedBuilding: null,
    selectedGateway: null,
    selectedDevice: null,
    selectedGroupOfDevices: null,
    selectedPage: null,
    selectedAccount: null,
    item: null,
    loading: false,
    reload : '',
    adminModeOn: false,
    isUpdateAccountDialogOpen: false,
    isUpdateUserDialogOpen: false,
    isUpdateBuildingDialogOpen: false,
    isUpdateGatewayDialogOpen: false,
    isUpdateDeviceDialogOpen: false,
    isUpdateGroupOfDevicesDialogOpen: false,
    isAddAccountDialogOpen: false,
    isAddAlertDialogOpen: false,
    alertDialogOpen: false,
    currentUserBuildings: false,
    isCreateUserDialogOpen: false,
    isCreateBuildingDialogOpen: false,
    isCreateGatewayDialogOpen: false,
    isCreateDeviceDialogOpen: false,
    isAddToDeviceGroupDialogOpen: false,
    updatedUserSuccess: false,
    addGroupDevice: null,
    isMilink: false,
    consultantMode: false,
}

export const useMainStore = create<MainState>()(
    persist(
        (set, get) => ({
            ...initialState,
            setMain: (update: Partial<MainState>) => set((state) => ({...state, ...update})), //Mise a jour du store
            reset: () => set(() => ({...initialState})), // Ajout de l'action de réinitialisation
        }),
        {
            name: 'main-storage',
        }
    )
);
